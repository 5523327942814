import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSession } from '../api/auth';

const initialState = {
  id: null,
  email: '',
  isLoggedIn: false,
  isLoadingUser: false,
};

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const fetchUser = createAsyncThunk('user/fetch', async (token) => {
  const jwtData = parseJwt(token);
  return await fetchSession(jwtData?.scp);
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setUser: (state, { payload }) => {
      state.isLoggedIn = true;
      Object.assign(state, payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.isLoggedIn = true;
        state.isLoadingUser = false;

        const [model] = Object.keys(payload);
        Object.assign(state, {
          model,
          role: payload.role || model,
          authorizePermissions: [],
          ...payload[model],
        });
      });
  },
});

export const { reset, setUser } = userSlice.actions;

export default userSlice.reducer;
