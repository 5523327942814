/* eslint-disable no-undef */
import ActionCable from 'actioncable';
import axios from 'axios';
import { keysToSnakeCase, keysToCamel } from './object';
import queryString from 'query-string';

const codeErrorHandler = {};

const retrieveFilename = (headers) => {
  let name = '';

  if (
    headers['content-disposition'] &&
    headers['content-disposition'].split("UTF-8''").length === 2
  ) {
    name = decodeURIComponent(
      headers['content-disposition'].split("UTF-8''")[1]
    );
  }

  return name;
};

axios.interceptors.response.use(
  (response) => {
    if (response.config.responseType === 'blob') {
      response.filename = retrieveFilename(response.headers);
      return response;
    }

    return {
      ...response,
      data: keysToCamel(response.data),
    };
  },
  (error) => {
    if (
      error.response &&
      error.response.status &&
      typeof codeErrorHandler[error.response.status] === 'function'
    ) {
      codeErrorHandler[error.response.status]();
    } else {
      return Promise.reject(error);
    }
  }
);

export const setCodeErrorHandler = (code, handler) => {
  codeErrorHandler[code] = handler;
};

export const API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL + '/api'
  : '/api';
export const appendBase = (url) => `${API_URL}${url}`;

export const get = (url, params = {}, config = {}) => {
  url +=
    Object.keys(params).length > 0
      ? '?' + queryString.stringify(keysToSnakeCase(params))
      : '';
  return axios.get(appendBase(url), {
    maxRedirects: 0,
    headers: {
      Accept: 'application/json',
    },
    ...config,
  });
};
export const post = (url, payload, options = {}, config = {}) => {
  if (options.transformKeys !== false) {
    payload = keysToSnakeCase(payload);
  }
  return axios.post(appendBase(url), payload, {
    maxRedirects: 0,
    headers: {
      Accept: 'application/json',
    },
    ...config,
  });
};
export const put = (url, payload, options = {}, config = {}) => {
  if (options.transformKeys !== false) {
    payload = keysToSnakeCase(payload);
  }
  return axios.put(appendBase(url), payload, {
    maxRedirects: 0,
    headers: {
      Accept: 'application/json',
    },
    ...config,
  });
};
export const destroy = (url, payload, config = {}) => {
  return axios.delete(appendBase(url), keysToSnakeCase(payload), config);
};

export const setAuthToken = (token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
};

export const deleteAuthToken = () => {
  delete axios.defaults.headers.common.Authorization;
};

export const getWS = () => {
  let url = process.env.REACT_APP_WS_URL;

  if (!url) {
    const protocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';
    const host = window.location.host;
    url = `${protocol}://${host}`;
  }
  return ActionCable.createConsumer(
    `${url}/cable?token=${
      axios.defaults.headers.common.Authorization.split(' ')[1]
    }`
  );
};

const request = { get, post, put, destroy };

export default request;
