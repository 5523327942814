import { post, destroy, get } from '../util/request';

const getAuthConfig = (model) => {
  switch (model) {
    case 'worker':
      return {
        loginPath: '/v1/workers/sign_in',
        logoutPath: '/v1/workers/sign_out',
        sessionPath: '/v1/worker/session',
      };
    case 'auditor':
      return {
        loginPath: '/v1/auditors/sign_in',
        logoutPath: '/v1/auditors/sign_out',
        sessionPath: '/v1/auditor/session',
      };
    default:
      return {
        loginPath: '/v1/users/sign_in',
        logoutPath: '/v1/users/sign_out',
        sessionPath: '/v1/session',
      };
  }
};

export const login = async (user, model) => {
  if (!model) {
    throw new Error('Model is required to perform the login');
  }
  const authConfig = getAuthConfig(model);
  const res = await post(authConfig.loginPath, {
    [model]: user,
  });
  return res.data;
};

export const logout = async (model) => {
  const authConfig = getAuthConfig(model);
  const res = await destroy(authConfig.logoutPath);

  return res.data;
};

export const fetchSession = async (model) => {
  const authConfig = getAuthConfig(model);
  const res = await get(
    authConfig.sessionPath,
    {},
    {
      maxRedirects: 0,
      headers: {
        Accept: 'application/json',
      },
    }
  );
  return res.data;
};

const Auth = { fetchSession, login, logout };

export default Auth;
